import axios from "axios";

export default async (url, method, language) => {
  let data = JSON.parse(localStorage.getItem("data"));
  if (data) {
    data = data.lan;
  } else if(language){
    data = language
  } else data = 'tm'
  const headers = {
    "Accept-Language": data,
  };
  return await axios({ url, method, headers });
};
