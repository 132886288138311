import React, { useContext, useEffect, useState } from "react";
import url from "../setting.json";
import Axios from "../shared/api/axios";
import { LanguageContext } from "../shared/context/lan-context";
import axios from "axios";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
const Olympiad = () => {
  const grade = [
    { id: 0, name: "11 synp 6 ýaşly" },
    { id: 1, name: "11 synp 7 ýaşly" },
    { id: 2, name: "10 synp" },
    { id: 3, name: "9 synp" },
  ];
  const { language } = useContext(LanguageContext);
  // ------------------------------award-------------------------------------------
  const [olimpId, setOlimpId] = useState();
  const [taslamaId, setTaslamaId] = useState();
  const olimp = [
    { id: 0, name: "Mekdep derejede" },
    { id: 1, name: "Etrap derejede" },
    { id: 2, name: "Welayat derejede" },
    { id: 3, name: "Dowlet derejede" },
    { id: 4, name: "Halkara derejede" },
  ];
  const taslama = [
    { id: 0, name: "Mekdep derejede" },
    { id: 1, name: "Etrap derejede" },
    { id: 2, name: "Welayat derejede" },
    { id: 3, name: "Dowlet derejede" },
    { id: 4, name: "Halkara derejede" },
  ];

  // ------------------------------award-------------------------------------------
  const [welayat, setWelayat] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [etrap, setEtrap] = useState();
  const [schoolName, setSchoolName] = useState();
  const [welayatId, setWelayatId] = useState();
  const [etrapId, setEtrapId] = useState();
  const [preferedSubject, setPreferedSubject] = useState();
  const [preferedSubjectID, setPreferedSubjectID] = useState();
  const [mailAdress, setMailAdress] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [pupils1, setPupils1] = useState();

  const welayatlar = async () => {
    const apiUrl = `${url.DjangoUrl}olympiad/welayat/`;
    const resp = await Axios(apiUrl, "GET", language);
    setWelayat(resp.data);
  };
  const etraplar = async () => {
    if (welayatId) {
      const apiUrl = `${url.DjangoUrl}olympiad/etrap/?welayat=` + welayatId;
      const resp = await Axios(apiUrl, "GET", language);
      setEtrap(resp.data);
    }
  };
  const pupils = async () => {
    const apiUrl = `${url.DjangoUrl}olympiad/pupil/`;
    const resp = await Axios(apiUrl, "GET", language);
    setPupils1(resp.data);
  };
  const preferedSubjects = async () => {
    const apiUrl = `${url.DjangoUrl}olympiad/preferred-subject/`;
    const resp = await Axios(apiUrl, "GET", language);
    setPreferedSubject(resp.data);
  };
  useEffect(() => {
    etraplar();
  }, [welayatId]);
  useEffect(() => {
    preferedSubjects();
    welayatlar();
  }, []);
  const [is_open_reg, setIs_open_reg] = useState(false);
  // -------------------------------------------------------------------
  const handleDownload = () => {
    const doc = new jsPDF();

    doc.text(
      20,
      50,
      `Salam,${idCode.name} ${idCode.surname} sizin ID codynyz: ${idCode.identificator} `
    );
    doc.text(45, 20, `Turkmenistanyn Oguzhan adyndaky`);
    doc.text(45, 28, ` inzener-tehnalogiyalar universiteti`);
    doc.text(20, 60, `Olimpiyada baranynyzda id codynyzy yatdan cykarman`);
    const imgData = "/transpanrent-logo.png"; // Replace with the actual image data
    doc.addImage(imgData, "PNG", 20, 10, 20, 20);
    doc.save("Olimpiad registration code.pdf");
  };
  // ==============================================================
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [fatherName, setFatherName] = useState();
  const [gradeID, setGradeID] = useState();

  const [idCode, setIdCode] = useState("");
  const [list, setList] = useState(true);
  const afterSubmit = () => {
    setOpenModel(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(url.DjangoUrl + "olympiad/pupil/", {
        name: name,
        surname: surname,
        father_name: fatherName,
        grade: gradeID,
        welayat: welayatId,
        etrap: etrapId,
        school_name: schoolName,
        preferred_subject: preferedSubjectID,
        email: mailAdress,
        phone: phoneNumber,
        olympiad: olimpId,
        taslama: taslamaId,
      })
      .then(function (response) {
        if (response.status === 201) {
          setIdCode(response.data);
          setList(true);
        }
        if (response.status === 400) {
          setIdCode(response.data);
          setIdCode("Please check one more time");
        }
      })
      .catch(function (error) {
        setIdCode("Please check one more time");
        console.error(error);
      });
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (idCode) {
      afterSubmit();
    }
    pupils();
  }, [idCode]);
  return (
    <div className="mb-5">
      <form className="flex transition-all" onSubmit={handleSubmit}>
        <div className="mt-16 w-9/12 mx-auto">
          <div
            className="my-5 flex items-center gap-1"
            onClick={(e) => setIs_open_reg(!is_open_reg)}
          >
            <label className="min-w-max">{t("Olympiad registration")}</label>
            <div className="h-[1px] w-full bg-gray-300"></div>
            <img
              className="w-5"
              src={
                is_open_reg
                  ? "./caret_up_and_down/caret-down.svg"
                  : "./caret_up_and_down/caret-up.svg"
              }
              alt=""
            />
          </div>
          {/* {is_open_reg && ( */}
          <div
            className={is_open_reg ? "flex flex-col   gap-2" : "h-0 w-0 hidden"}
          >
            <input
              name="name"
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              type="text"
              placeholder={t("name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
            <input
              name="surname"
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              type="text"
              placeholder={t("surname")}
              required
              onChange={(e) => {
                setSurname(e.target.value);
              }}
            />
            <input
              name="father's_name"
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              type="text"
              placeholder={t("father name")}
              onChange={(e) => {
                setFatherName(e.target.value);
              }}
            />
            <select
              name="class"
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setGradeID(e.target.value)}
            >
              <option selected disabled>
                {t("grade")}
              </option>
              {grade?.map((item, i) => (
                <option key={i} value={item.id}>
                  {t(item.name)}
                </option>
              ))}
            </select>

            <select
              name="welayat"
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setWelayatId(e.target.value)}
            >
              <option selected disabled>
                Welaýat
              </option>
              {welayat?.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {welayatId && (
              <select
                name="etrap"
                className="border-[1px] border-gray-300 rounded-md px-3 py-2"
                onChange={(e) => setEtrapId(e.target.value)}
              >
                <option selected disabled>
                  Etraby
                </option>
                {welayatId &&
                  etrap?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
            <input
              type="text"
              required
              value={schoolName}
              placeholder={t("name of school")}
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <select
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setPreferedSubjectID(e.target.value)}
            >
              <option selected disabled>
                {t("Preferred subject")}
              </option>
              {preferedSubject?.map((item, i) => (
                <option key={i} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>

            {/* --------------------------diplom------------- */}

            <select
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setOlimpId(e.target.value)}
            >
              <option selected disabled>
                {t("Achievements in academic olympiads")}
              </option>
              {olimp?.map((item, i) => (
                <option key={i} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
            <select
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              onChange={(e) => setTaslamaId(e.target.value)}
            >
              <option selected disabled>
                {t(" Achievements in academic projects")}
              </option>
              {taslama?.map((item, i) => (
                <option key={i} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
            {/* --------------------------diplom------------- */}
            <input
              required
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              type="mail"
              placeholder={t("Email")}
              onChange={(e) => setMailAdress(e.target.value)}
            />
            <input
              required
              className="border-[1px] border-gray-300 rounded-md px-3 py-2"
              type="text"
              placeholder={t("Tel")}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            {openModel && (
              <div className="flex justify-center">
                <div
                  onClick={(e) => {
                    setOpenModel(false);
                    setIs_open_reg(false);
                  }}
                  className="fixed top-0 left-0 w-full h-full bg-black/30"
                ></div>
                <div className="fixed m-auto  top-56 bg-white p-10 rounded-md flex flex-col gap-5">
                  <div className="w-full bg-green-100 border-[1px] border-green-600 rounded-md py-3 px-5 text-green-600 text-center ">
                    Bu sizin registrasiya nomeriniz, buny yatda saklamagynyzy
                    hayys edyan:{" "}
                    <span className="font-bold text-xl">
                      {idCode.identificator}
                    </span>
                  </div>

                  <div
                    className="cursor-pointer text-center bg-blue-500 text-white p-2 rounded-md hover:bg-white hover:text-blue-500 border-[1px] border-blue-500 transition-all ease-in-out "
                    onClick={() => handleDownload()}
                  >
                    Download pdf of code{" "}
                  </div>
                </div>
              </div>
            )}

            <button
              className="bg-blue-500 text-white p-2 rounded-md hover:bg-white hover:text-blue-500 border-[1px] border-blue-500 transition-all ease-in-out "
              type="submit"
            >
              {t("Send")}
            </button>
          </div>
          {/* )} */}
        </div>
      </form>
      <div className=" w-9/12 mx-auto ">
        <div
          className="my-5 flex items-center gap-1"
          onClick={(e) => setList(!list)}
        >
          <label className="min-w-max">{t("Gatnashyjylar")}</label>
          <div className="h-[1px] w-full bg-gray-300"></div>
          <img
            className="w-5"
            src={
              list
                ? "./caret_up_and_down/caret-down.svg"
                : "./caret_up_and_down/caret-up.svg"
            }
            alt=""
          />
        </div>
        {list && (
          <table className="w-full p-2 border-collapse border bg-slate-100 border-slate-400 rounded-md">
            <tr>
              <th className="border border-slate-300 text-start p-3">№ </th>
              <th className="border border-slate-300 text-start p-3">Name</th>
              <th className="border border-slate-300 text-start p-3">
                Welayat
              </th>
              <th className="border border-slate-300 text-end p-3">School</th>
            </tr>
            {pupils1?.map((item, i) => (
              <tr key={i}>
                <td className="border border-slate-300 p-3">{item?.id}</td>
                <td className="border border-slate-300 p-3">
                  {item?.name} {item?.surname}
                </td>
                <td className="border border-slate-300 text-start p-3">
                  {item?.welayat?.name}
                </td>
                <td className="border border-slate-300 text-end p-3">
                  {item?.school_name}
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
    </div>
  );
};

// const DownloadButton = () => {
//   const handleDownload = async () => {
//     const elementToCapture = document.getElementById("pdf-content"); // The React component to capture
//     const canvas = await html2canvas(elementToCapture);
//     const imageData = canvas.toDataURL("image/png");

//     const pdf = new jsPDF();
//     pdf.addImage(imageData, "PNG", 0, 0);

//     const fileName = "generated-pdf.pdf";
//     pdf.save(fileName);
//   };

//   return (
//     <div className="cursor-pointer" onClick={handleDownload}>
//       Download PDF
//     </div>
//   );
// };

// const PDFContent = ({ idCode }) => {
//   return (
//     <div
//       id="pdf-content"
//       className="p-5 border-[1px] border-gray-300 rounded-md"
//     >
//       <h1>
//         Salam {idCode?.name} {idCode?.surname} bu senin kodynyz yatdan cykarman
//       </h1>
//       <p>{idCode?.identificator}</p>
//     </div>
//   );
// };
export default Olympiad;
